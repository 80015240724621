<template>
    <div>
        <b-card>
            <apexchart :height="400" type="bar" :options="chartOptions" :series="graphDat"></apexchart>
        </b-card>
    </div>
</template>
<script>

    import {BCard} from 'bootstrap-vue'
    import VueApexCharts from 'vue-apexcharts'


    export default {
        components: {
            BCard,
            apexchart: VueApexCharts
        },
        props:{
            dateFrom: {
                type: Date
            },
            dateTo: {
                type: Date
            },
            filterTable:{
                type: String
            },
            filterDataAreas:{
                type: Array
            },
            filterDataTerritories:{
                type: Array
            },
            filterDataDistricts:{
                type: Array
            },
            newData: {
                type: Boolean
            }
        },
        watch:{
            newData() {
                this.getTotalOutletsReached()
            }
        },
        data() {
            return {
                graphDat: [],
                chartOptions: {
                    
                    chart: {
                        type: 'bar',
                        zoom: {
                            enabled: false
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    title: {
                        text: 'Total outlet reached',
                        align: 'left'
                    },
                    legend: {
                        tooltipHoverFormatter(val, opts) {
                            return `${val  } - ${  opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]  }`
                        }
                    },
                    xaxis: {
                        categories: []
                    },
                    tooltip: {
                        y: [
                            {
                                title: {
                                    formatter(val) {
                                        return val
                                    }
                                }
                            }
                        ]
                    },
                    grid: {
                        borderColor: '#f1f1f1'
                    }
                }
            }
        },
        methods:{
            async getTotalOutletsReached() {

                const dateFrom = this.moment(this.dateFrom).toDate()
                const dateTo = this.moment(this.dateTo).toDate()

                const diff = dateTo.getTime() - dateFrom.getTime()

                const differenceInDays = parseInt(diff / (1000 * 60 * 60 * 24))

                const tmpOptions = {...this.chartOptions}
                tmpOptions.xaxis.categories = []
                this.chartOptions = tmpOptions
                
                const date = this.moment(dateFrom)
                for (let index = 0; index < differenceInDays + 1; index++) {
                    this.chartOptions.xaxis.categories.push(date.format('DD MMM'))
                    date.add(1, 'days')
                }

                const payload = {
                    'table': this.filterTable,
                    'dataFilterAreas': this.filterDataAreas,
                    'dataFilterTerritories': this.filterDataTerritories,
                    'dataFilterDistricts': this.filterDataDistricts
                }

                try {
                    const dateFromFormated = this.moment(this.dateFrom).startOf('day').format('YYYY-MM-DD')
                    const dateToFormated = this.moment(this.dateTo).endOf('day').format('YYYY-MM-DD')

                    const data = await this.$http.put(`/api/management/v1/report/total_outlets_reached?date_from=${dateFromFormated}&date_to=${dateToFormated}`, payload)

                    this.graphDat = [
                        {
                            name: 'Total outlet reached',
                            data: data.data
                        }
                    ]
                } catch (err) {
                    this.$printError('Error while loading data!')
                }
            }

        },
        mounted() {
            this.getTotalOutletsReached()
        }

    }
</script>
<style lang="scss">

</style>
