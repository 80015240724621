<template>
    <div>
        <b-card>
            <div class="d-flex justify-content-center">

                <div class="circle-class d-flex justify-content-center align-items-center">
                    <truck-icon size="2x" class="custom-class" style="color: #ea5555;"></truck-icon>
                </div>
            </div>

            <div class="d-flex justify-content-center">
                <div class="h4 my-1" style="font-weight: 900;">
                    {{count}}
                </div>
            </div>

            <div class="d-flex justify-content-center">
                <div class="h5 mb-2" style="font-weight: normal;">
                    Unique outlets reached
                </div>
            </div>

        </b-card>
    </div>
</template>
<script>

    import {BCard} from 'bootstrap-vue'
    import { TruckIcon } from 'vue-feather-icons'

    export default {
        components: {
            BCard,
            TruckIcon
        },
        props:{
            dateFrom: {
                type: Date
            },
            dateTo: {
                type: Date
            },
            filterTable:{
                type: String
            },
            filterDataAreas:{
                type: Array
            },
            filterDataTerritories:{
                type: Array
            },
            filterDataDistricts:{
                type: Array
            },
            newData: {
                type: Boolean
            }
        },
        watch:{
            newData() {
                this.getUniqueOutletsReached()
            }
        },
        data() {
            return {
                valueChecked: [],
                categories: [],
                count: null
            }
        },
        methods:{
            async getUniqueOutletsReached() {

                const dateFromFormated = this.moment(this.dateFrom).startOf('day').format('YYYY-MM-DD')
                const dateToFormated = this.moment(this.dateTo).endOf('day').format('YYYY-MM-DD')

                const payload = {
                    'table': this.filterTable,
                    'dataFilterAreas': this.filterDataAreas,
                    'dataFilterTerritories': this.filterDataTerritories,
                    'dataFilterDistricts': this.filterDataDistricts
                }

                try {
                    const data = await this.$http.put(`/api/management/v1/report/unique_outlets_reached?date_from=${dateFromFormated}&date_to=${dateToFormated}`, payload)
                    
                    if (data.data && data.data.length > 0) {
                        this.count = data.data[0].count
                    } else if (data.data) {
                        this.count = 0
                    }
                } catch (err) {
                    this.$printError('Error while loading data!')
                }
            }

        },
        mounted() {
            this.getUniqueOutletsReached()
        }

    }
</script>
<style lang="scss">
    .circle-class{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #fceaeb !important;
    }
</style>