<template>
    <div>
        <b-card>
            <div class="h4 mb-2" style="font-weight: 900;">
                Total sales
            </div>
            <div class="d-flex justify-content-center mb-2">
                <b-form-checkbox
                    v-for="(category,id) in categories"
                    v-model="valueChecked"
                    :value="category.id"
                    @change="getTotalSales()"
                    :key="'checkbox_category' + id"
                    class="ml-2"
                >
                    {{category.name}}
                </b-form-checkbox>
            </div>
            <apexchart :height="400" type="line" :options="chartOptions" :series="graphDat"></apexchart>
        </b-card>
    </div>
</template>
<script>

    import {BCard, BFormCheckbox} from 'bootstrap-vue'
    import VueApexCharts from 'vue-apexcharts'


    export default {
        components: {
            BCard,
            apexchart: VueApexCharts,
            BFormCheckbox
        },
        props:{
            dateFrom: {
                type: Date
            },
            dateTo: {
                type: Date
            },
            filterTable:{
                type: String
            },
            filterDataAreas:{
                type: Array
            },
            filterDataTerritories:{
                type: Array
            },
            filterDataDistricts:{
                type: Array
            },
            newData: {
                type: Boolean
            }
        },
        watch:{
            newData() {
                this.getTotalSales()
            }
        },
        data() {
            return {
                valueChecked: [],
                categories: [],
                graphDat: [],
                chartOptions: {
                    chart: {
                        type: 'line',
                        zoom: {
                            enabled: false
                        }
                    },
                    colors:['#25F4DE', '#EA7C95', '#DDE56D', '#896D54', '#CE6819', '#239190', '#67F162', '#C62099', '#6D48BE', '#AA7A17', '#11FF28'],
                    dataLabels: {
                        enabled: false
                    },
                    legend: {
                        tooltipHoverFormatter(val, opts) {
                            return `${val  } - ${  opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]  }`
                        }
                    },
                    xaxis: {
                        categories: []
                    },
                    tooltip: {
                        y: [
                            {
                                title: {
                                    formatter(val) {
                                        return val
                                    }
                                }
                            }
                        ]
                    },
                    grid: {
                        borderColor: '#f1f1f1'
                    }
                }
            }
        },
        methods:{
            async getCategories() {

                try {

                    const data = await this.$http.get('/api/management/v1/product_category')

                    if (data.data && data.data.length === 0) return

                    this.categories = data.data

                    this.valueChecked = []
                    for (const category of data.data) {
                        this.valueChecked.push(category.id)
                    }

                } catch (err) {
                    this.$printError('Error while loading data!')
                }
            },
            async getTotalSales() {
                const diff = this.dateTo.getTime() - this.dateFrom.getTime()

                const differenceInDays = parseInt(diff / (1000 * 60 * 60 * 24))

                const tmpOptions = {...this.chartOptions}
                tmpOptions.xaxis.categories = []
                this.chartOptions = tmpOptions
                
                const date = this.moment(this.dateFrom)
                for (let index = 0; index < differenceInDays + 1; index++) {
                    this.chartOptions.xaxis.categories.push(date.format('DD MMM'))
                    date.add(1, 'days')
                }

                const dateFromFormated = this.moment(this.dateFrom).startOf('day').format('YYYY-MM-DD')
                const dateToFormated = this.moment(this.dateTo).endOf('day').format('YYYY-MM-DD')

                const payload = {
                    'table': this.filterTable,
                    'dataFilterAreas': this.filterDataAreas,
                    'dataFilterTerritories': this.filterDataTerritories,
                    'dataFilterDistricts': this.filterDataDistricts,
                    'categories': this.valueChecked
                }

                try {
                    const colors = []
                    const data = await this.$http.put(`/api/management/v1/report/total_sales?date_from=${dateFromFormated}&date_to=${dateToFormated}`, payload)
                    
                    if (data.data && data.data.length > 0) {
                        this.graphDat = data.data

                        for (const ele of data.data) {
                            colors.push(ele.color)
                        }

                        const tmpOptions = {...this.chartOptions}
                        this.chartOptions = tmpOptions
                        this.chartOptions.colors = colors
                    } else if (data.data) {
                        this.graphDat = []
                    }
                } catch (err) {
                    this.$printError('Error while loading data!')
                }
            }

        },
        mounted() {
            this.getCategories()
            this.getTotalSales()
        }

    }
</script>
<style lang="scss">

</style>