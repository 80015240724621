<template>
    <div>
        <show-sales-by-territory :salesData="salesData" title="Sales by District" id="sales_by_territory"></show-sales-by-territory>

        <show-sales-by-territory :salesData="salesData" :averageDropSize="10" title="Average drop size" id_view="average_drop_size"></show-sales-by-territory>
    </div>
</template>
<script>

    import { } from 'bootstrap-vue'
    import ShowSalesByTerritory from './ShowSalesByTerritory.vue'

    export default {
        components: {
            // BCardText,
            ShowSalesByTerritory
        },

        props:{
            dateFrom: {
                type: Date
            },
            dateTo: {
                type: Date
            },
            filterTable:{
                type: String
            },
            filterDataAreas:{
                type: Array
            },
            filterDataTerritories:{
                type: Array
            },
            filterDataDistricts:{
                type: Array
            },
            newData: {
                type: Boolean
            }
        },
        watch:{
            newData() {
                this.getSalesByTerritory()
            }
        },
        data() {
            return {
                salesData: null
            }
        },
        methods:{
            async getSalesByTerritory() {

                const dateFromFormated = this.moment(this.dateFrom).format('YYYY-MM-DD')
                const dateToFormated = this.moment(this.dateTo).format('YYYY-MM-DD')

                const payload = {
                    'table': this.filterTable,
                    'dataFilterAreas': this.filterDataAreas,
                    'dataFilterTerritories': this.filterDataTerritories,
                    'dataFilterDistricts': this.filterDataDistricts
                }

                try {
                    const data = await this.$http.put(`/api/management/v1/report/sales_by_territory?date_from=${dateFromFormated}&date_to=${dateToFormated}`, payload)
                    if (data.data) this.salesData = data.data

                } catch (err) {
                    this.$printError('Error while loading data!')
                }
            }

        },
        mounted() {
            this.getSalesByTerritory()
        }

    }
</script>
<style lang="scss">

</style>