<template>
    <div>
        <b-card>

            <div class="h4 mb-2" style="font-weight: 900;">
                {{title}}
            </div>
            <div class="h6 mb-2" style="font-weight: normal;" v-if="salesData && salesData.length === 0">
                No data to display
            </div>
        
            <b-card no-body class="my-0" v-for="(district, id) in salesData" :key="id_view +'sale_id_' + id">
                <b-card-header header-tag="header" class="p-0" role="tab" style="padding-bottom: 10px !important;">
                    <b-button block v-b-toggle="id_view + district.id" style="background: #7d7c7a;">
                        <div class="d-flex justify-content-between">
                            <div>
                                DISTRICT: {{district.distric_name }}
                            </div>

                            <div v-if="averageDropSize == 0">
                                Qty: {{ district.qty }}
                            </div>
                            <div v-if="averageDropSize != 0">
                                Qty: {{ parseFloat(district.qty/district.outlet_count).toFixed(2) }}
                            </div>
                        </div>
                    </b-button>
                </b-card-header>

                <!-- DISTRICT -->
                <b-collapse :id="id_view + district.id" :accordion="id_view + 'district-accordion'" role="tabpanel">
                    <b-card-body class="py-0" style="padding-top: 0px !important; padding-bottom: 15px !important;">
                        <!-- <div class="m-0" style="padding-bottom: 10px !important;" v-for="(area, num_id) in sale.areas" :key="'area_id_' + num_id + '_' + sale.id + area.name"> -->
                        <b-card no-body class="my-0" v-for="(territory, id) in district.territory" :key="id_view + 'sale_id_' + id">
                            <b-card-header header-tag="header" class="p-0" role="tab" style="padding-bottom: 10px !important;">
                                <b-button block v-b-toggle="id_view + territory.id" style="background: #a6a6a6 !important; border:none;">
                                    <div class="d-flex justify-content-between">
                                        <div>
                                            TERRITORY: {{territory.territory_name }}
                                        </div>

                                        <div v-if="averageDropSize == 0">
                                            Qty: {{ territory.qty }}
                                        </div>
                                        <div v-if="averageDropSize != 0">
                                            Qty: {{ parseFloat(territory.qty/territory.outlet_count).toFixed(2) }}
                                        </div>
                                    </div>
                                </b-button>
                            </b-card-header>

                            <!-- TERRITORY -->
                            <b-collapse :id="id_view + territory.id" :accordion="id_view + 'territory-accordion'" role="tabpanel">
                                <b-card-body class="py-0" style="padding-top: 0px !important; padding-bottom: 15px !important;">

                                    <!-- AREA -->
                                    <div class="m-0" style="padding-bottom: 10px !important;" v-for="(area, num_id) in territory.areas" :key="id_view + 'area_id_' + num_id + '_' + territory.id + area.name">
                                        <div>
                                            <div no-body class="ml-3 d-flex justify-content-between" style="background: #c9c8c7; border-radius: 10px; padding: 10px;">
                                                <div class="ml-1">
                                                    AREA: {{ area.name }}
                                                </div>
                                                <div v-if="averageDropSize == 0">
                                                    Qty: {{ area.qty }}
                                                </div>
                                                <div v-if="averageDropSize != 0">
                                                    Qty: {{ parseFloat(area.qty/area.outlet_count).toFixed(2) }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-card-body>
                            </b-collapse>

                        </b-card>
                        <!-- </div> -->
                    </b-card-body>
                </b-collapse>

            </b-card>
            
        </b-card>
    </div>
</template>
<script>

    import { BCard, BCollapse, BButton, BCardBody, BCardHeader, VBToggle } from 'bootstrap-vue'

    export default {
        components: {
            BCard,
            BCollapse,
            BButton,
            BCardBody, 
            // BCardText,
            BCardHeader
        },
        directives: {
            'b-toggle': VBToggle 
        },
        props:{
            salesData: {
                type: Array
            },
            averageDropSize:{
                type: Number,
                default: 0
            },
            title:{
                type: String
            },
            id_view:{
                type: String
            }
        }   
    }
</script>
<style lang="scss">

</style>