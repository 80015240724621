<template>
    <div>
        <b-card>
            <div class="h4 mb-2" style="font-weight: 900;">
                Number of new outlets: {{numberOfAllOutlets}}
            </div>
            <vue-good-table
                :columns="columns"
                :rows="outletsData"
                :pagination-options="paginatioOptions"
            />
        </b-card>
    </div>
</template>
<script>

    import {BCard} from 'bootstrap-vue'
    import { VueGoodTable } from 'vue-good-table'
    import 'vue-good-table/dist/vue-good-table.css'

    export default {
        components: {
            BCard,
            VueGoodTable
        },
        props:{
            dateFrom: {
                type: Date
            },
            dateTo: {
                type: Date
            },
            filterTable:{
                type: String
            },
            filterDataAreas:{
                type: Array
            },
            filterDataTerritories:{
                type: Array
            },
            filterDataDistricts:{
                type: Array
            },
            newData: {
                type: Boolean
            }
        },
        watch:{
            newData() {
                this.newOutlets()
            }
        },
        data() {
            return {
                paginatioOptions: {
                    enabled: true,
                    mode: 'records',
                    perPage: 20,
                    position: 'top',
                    perPageDropdown: [3, 7, 9],
                    dropdownAllowAll: false,
                    setCurrentPage: 2,
                    jumpFirstOrLast : true,
                    firstLabel : 'First Page',
                    lastLabel : 'Last Page',
                    nextLabel: 'next',
                    prevLabel: 'prev',
                    rowsPerPageLabel: 'Rows per page',
                    ofLabel: 'of',
                    pageLabel: 'page', // for 'pages' mode
                    allLabel: 'All'
                },
                outletsData:null,
                columns:[
                    {
                        label: 'Outlet',
                        field: 'outlet_name'
                    },
                    {
                        label: 'Date (First sale)',
                        field: 'time'
                    },
                    {
                        label: 'Area',
                        field: 'area_name'
                    },
                    {
                        label: 'Territory',
                        field: 'territory_name'
                    },
                    {
                        label: 'District',
                        field: 'district_name'
                    }
                ],
                numberOfAllOutlets: null
            }
        },
        methods:{
            async newOutlets() {

                try {

                    const dateFromFormated = this.moment(this.dateFrom).startOf('day').format('YYYY-MM-DD')
                    const dateToFormated = this.moment(this.dateTo).endOf('day').format('YYYY-MM-DD')

                    const payload = {
                        'table': this.filterTable,
                        'dataFilterAreas': this.filterDataAreas,
                        'dataFilterTerritories': this.filterDataTerritories,
                        'dataFilterDistricts': this.filterDataDistricts
                    }

                    const data = await this.$http.put(`/api/management/v1/report/new_outlets?date_from=${dateFromFormated}&date_to=${dateToFormated}`, payload)

                    if (!data.data) return

                    this.numberOfAllOutlets = data.data.length

                    this.outletsData = data.data

                    let i = 1
                    for (const object of this.outletsData) {
                        object.number = i++
                    }
                } catch (err) {
                    this.$printError('Error while loading data 12312321!')
                }
            }

        },
        mounted() {
            this.newOutlets()
        }

    }
</script>