<template>
    <div>
        <b-card>
            <div class="h4 mb-2" style="font-weight: 900;">
                Top 20 performing outlets
            </div>
            <vue-good-table
                :columns="columns"
                :rows="outletsData"
            />
        </b-card>
    </div>
</template>
<script>

    import {BCard} from 'bootstrap-vue'
    import { VueGoodTable } from 'vue-good-table'
    import 'vue-good-table/dist/vue-good-table.css'

    export default {
        components: {
            BCard,
            VueGoodTable
        },
        props:{
            dateFrom: {
                type: Date
            },
            dateTo: {
                type: Date
            },
            filterTable:{
                type: String
            },
            filterDataAreas:{
                type: Array
            },
            filterDataTerritories:{
                type: Array
            },
            filterDataDistricts:{
                type: Array
            },
            newData: {
                type: Boolean
            }
        },
        watch:{
            newData() {
                this.getTopPerformingOutelts()
            }
        },
        data() {
            return {
                outletsData:null,
                columns:[
                    {
                        label: 'Place',
                        field: 'number'
                    },
                    {
                        label: 'Outlet',
                        field: 'outlet_name'
                    },
                    {
                        label: 'Area',
                        field: 'area_name'
                    },
                    {
                        label: 'Territory',
                        field: 'territory_name'
                    },
                    {
                        label: 'District',
                        field: 'district_name'
                    }
                ]
            }
        },
        methods:{
            async getTopPerformingOutelts() {

                try {

                    const dateFromFormated = this.moment(this.dateFrom).startOf('day').format('YYYY-MM-DD')
                    const dateToFormated = this.moment(this.dateTo).endOf('day').format('YYYY-MM-DD')

                    const payload = {
                        'table': this.filterTable,
                        'dataFilterAreas': this.filterDataAreas,
                        'dataFilterTerritories': this.filterDataTerritories,
                        'dataFilterDistricts': this.filterDataDistricts
                    }

                    const data = await this.$http.put(`/api/management/v1/report/top20_outlets?date_from=${dateFromFormated}&date_to=${dateToFormated}`, payload)

                    this.outletsData = data.data

                    let i = 1
                    for (const object of this.outletsData) {
                        object.number = i++
                    }
                } catch (err) {
                    this.$printError('Error while loading data!')
                }
            }

        },
        mounted() {
            this.getTopPerformingOutelts()
        }

    }
</script>