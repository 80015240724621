<template>
    <b-card>
        <div class="h4 mb-2" style="font-weight: 900;">
            Custom Report
        </div>
        <div class="row mb-2">
            <div class="col-12 col-md-6 mt-2">
                Date from:
                <b-form-datepicker
                    v-model="dateFrom"
                    value-as-date
                ></b-form-datepicker>
            </div>
            
            <div class="col-12 col-md-6 mt-2">
                Date to:
                <b-form-datepicker
                    v-model="dateTo"
                    value-as-date
                ></b-form-datepicker>
            </div>
        </div>

        <div class="row">
            <div class="col-4">
                <div>
                    District
                </div>
                <v-select :options="dataDistricts" label="name" v-model="selectedDistrictFilter" @option:selected="optionAddedDistrict"  @option:deselected="optionRemovedDistrict" multiple>
                    <template #option="district">
                        {{district.name}}
                    </template>

                </v-select>
            </div>
            <div class="col-4">
                <div>
                    Territory
                </div>
                <v-select :options="dataTerritories" label="name" @option:selected="optionAddedTerritory" @option:deselected="optionRemovedTerritory" v-model="selectedTerritoryFilter" multiple>
                    <template #option="territory">
                        {{territory.name}}
                        <div style="font-size: 0.7rem;">
                            {{territory.district}}
                        </div>
                    </template>

                </v-select>
            </div>
            <div class="col-4">
                <div>
                    Area
                </div>
                <v-select :options="dataAreas" label="name"  @option:selected="optionAddedArea" v-model="selectedAreasFilter" multiple>
                    <template #option="area">
                        {{area.name}}
                        <div style="font-size: 0.7rem;">
                            {{area.district}} -> {{area.territory}}
                        </div>
                    </template>

                </v-select>
            </div>
                
        </div>


        <div class="row m-3">
            <div class="col-lg-3 col-md-4 col-6 mt-lg-1 mt-3">
                Sales
                <div class="mt-1">
                    <b-form-checkbox v-model="selectedSales.time" @change="updateSelect('Time', selectedSales.time, 'date')">Time</b-form-checkbox>
                    <b-form-checkbox v-model="selectedSales.qty">Quantity</b-form-checkbox>
                    <b-form-checkbox v-model="selectedSales.price">Price</b-form-checkbox>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-6 mt-lg-1 mt-3">
                Product
                <div class="mt-1">
                    <b-form-checkbox v-model="selectedProduct.unit" @change="updateSelect('Unit', selectedProduct.unit, 'unit')">Unit</b-form-checkbox>
                    <b-form-checkbox v-model="selectedProduct.name" @change="updateSelect('Product name', selectedProduct.name, 'product_name')" >Product name</b-form-checkbox>
                    <b-form-checkbox v-model="selectedProduct.category" @change="updateSelect('Category', selectedProduct.category, 'category')">Category name</b-form-checkbox>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-6 mt-lg-1 mt-3">
                Outlet
                <div class="my-1">
                    <b-form-checkbox v-model="selectedOutlet.name" @change="updateSelect('Outlet name', selectedOutlet.name, 'outlet_name')">Name</b-form-checkbox>
                    <!-- <b-form-checkbox v-model="selectedOutlet.owner">Owner</b-form-checkbox> -->
                </div>

                User
                <div class="mt-1">
                    <b-form-checkbox v-model="selectedUser.username" @change="updateSelect('Username', selectedUser.username, 'username')">Username</b-form-checkbox>
                    <b-form-checkbox v-model="selectedUser.first_name">First name</b-form-checkbox>
                    <b-form-checkbox v-model="selectedUser.last_name">Last name</b-form-checkbox>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-6 mt-lg-1 mt-3">
                Area
                <div class="my-1">
                    <b-form-checkbox v-model="selectedArea.name" @change="updateSelect('Area name', selectedArea.name, 'area_name')">Name</b-form-checkbox>
                </div>

                Territory
                <div class="my-1">
                    <b-form-checkbox v-model="selectedTerritory.name" @change="updateSelect('Territory name', selectedTerritory.name, 'territory_name')">Name</b-form-checkbox>
                </div>

                District
                <div class="mt-1">
                    <b-form-checkbox v-model="selectedDistrict.name" @change="updateSelect('District name', selectedDistrict.name, 'district_name')">Name</b-form-checkbox>
                </div>
            </div>
        </div>

        <div class="my-2" v-if="selectedSales.qty || selectedSales.price">
            GROUP BY
            <v-select 
                :options="selectOptions"
                v-model="selected"
                :reduce="selected => selected.key"
                multiple
            >

            </v-select>
        </div>

        <div class="d-flex justify-content-between">
            <b-button @click="getCustomReportData()" class="mb-2 btn-success">
                GO
            </b-button>

            <b-button @click="getExportToExcel()" class="mb-2 btn-warning">
                Export to excel
            </b-button>
        </div>

        <vue-good-table
            :columns="columns"
            :rows="rows"
            :pagination-options="paginatioOptions"
        />

    </b-card>
    
</template>

<script>
    import { BCard, BFormCheckbox, BButton, BFormDatepicker } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import { VueGoodTable } from 'vue-good-table'
    import 'vue-good-table/dist/vue-good-table.css'
    import Vue from 'vue'

    export default {
        components:{
            // BFormSelectOption,
            // BFormSelect,
            BCard,
            // BFormGroup,
            BFormCheckbox,
            vSelect,
            BButton,
            VueGoodTable,
            BFormDatepicker
        },
        data() {
            return {
                checkIfAlreadyExists: {
                    'district': {},
                    'territory': {},
                    'area': {}
                },
                dataAreas: [],
                dataTerritories: [],
                dataDistricts: [],
                selectedTerritoryFilter: [],
                selectedDistrictFilter: [],
                selectedAreasFilter: [],
                filterTable: '',
                filterDataAreas: [],
                filterDataTerritories: [],
                filterDataDistricts: [],
                dateFrom: this.moment().subtract(7, 'd').toDate(),
                dateTo: new Date(),
                selected: [],
                selectOptions: [],
                paginatioOptions: {
                    enabled: true,
                    mode: 'records',
                    perPage: 20,
                    position: 'top',
                    perPageDropdown: [3, 7, 9],
                    dropdownAllowAll: false,
                    setCurrentPage: 2,
                    jumpFirstOrLast : true,
                    firstLabel : 'First Page',
                    lastLabel : 'Last Page',
                    nextLabel: 'next',
                    prevLabel: 'prev',
                    rowsPerPageLabel: 'Rows per page',
                    ofLabel: 'of',
                    pageLabel: 'page', // for 'pages' mode
                    allLabel: 'All'
                },
                columns: [],
                rows: [],
                selectedUser:{
                    'username': false,
                    'first_name': false,
                    'last_name': false
                },
                selectedOutlet: {
                    'name': false,
                    'owner': false
                },
                selectedProduct: {
                    'unit': false,
                    'name': false,
                    'category': false
                },
                selectedSales: {
                    'time': false,
                    'qty': false,
                    'price': false
                },
                selectedOutletSegment: {
                    'name': false
                },
                selectedArea: {
                    'name': false
                },
                selectedTerritory: {
                    'name': false
                },
                selectedDistrict: {
                    'name': false
                }
            }
        },
        methods:{
            optionAddedDistrict(val) {
                const current_district = val[val.length - 1]
                this.checkIfAlreadyExists.district[current_district.id] = true
            },
            optionRemovedDistrict(removed_district) {

                const result = this.selectedTerritoryFilter.filter(ele => ele.district_id === removed_district.id)
                
                if (result) {
                    for (const territory_to_remove of result) {
                        
                        const index = this.selectedTerritoryFilter.findIndex(ele => ele.district_id === removed_district.id)
                        Vue.delete(this.selectedTerritoryFilter, index)

                        this.optionRemovedTerritory(territory_to_remove)
                    }
                }

                this.checkIfAlreadyExists.district[removed_district.id] = false
            },
            optionRemovedTerritory(territory_to_remove) {

                const result = this.selectedAreasFilter.filter(ele => ele.territory_id === territory_to_remove.id)

                if (result) {
                    for (const area_to_remove of result) {
                        const index = this.selectedAreasFilter.findIndex(ele => ele.id === area_to_remove.id)

                        Vue.delete(this.selectedAreasFilter, index)
                    }
                }

                this.checkIfAlreadyExists.territory[territory_to_remove.id] = false
            },
            optionAddedTerritory(val) {
                const current_territory = val[val.length - 1]
                this.checkIfAlreadyExists.territory[current_territory.id] = true

                if (!this.checkIfAlreadyExists.district[current_territory.district_id]) {
                    const result = this.dataDistricts.find(ele => ele.id === current_territory.district_id)
                    
                    if (result) {
                        this.selectedDistrictFilter.push(result)
                        this.checkIfAlreadyExists.district[current_territory.district_id] = true
                    }
                    
                }
            },
            optionAddedArea(val) {
                const current_area = val[val.length - 1]

                if (!this.checkIfAlreadyExists.territory[current_area.territory_id]) {
                    const result = this.dataTerritories.find(ele => ele.id === current_area.territory_id)
                    
                    if (result) {
                        this.selectedTerritoryFilter.push(result)
                        this.checkIfAlreadyExists.territory[current_area.territory_id] = true
                        this.optionAddedTerritory(this.selectedTerritoryFilter)
                    }
                    
                }
            },
            getNewData() {
                
                //If we selected area for example (Maribor -> Sever -> Center) we only add Center id, becuase we want only that data. 
                //If we would addded maribor and sever it would find all data in Maribor and not only data in Center Area

                this.filterDataAreas = []
                this.filterDataTerritories = []
                this.filterDataDistricts = []
                
                //We filter out dictricts if we also have filter for territory or area in that same districts. 
                for (const district of this.selectedDistrictFilter) {
                    const result_area = this.selectedAreasFilter.findIndex(ele => ele.district_id === district.id)
                    const result_territory = this.selectedTerritoryFilter.findIndex(ele => ele.district_id === district.id)

                    if (result_area === -1 && result_territory === -1) {
                        //Extracts only ids from struct.
                        this.filterDataDistricts.push(district.id)
                    }
                }

                //We filter out territories if we selected Are from that territory.
                for (const territory of this.selectedTerritoryFilter) {
                    const result_area = this.selectedAreasFilter.findIndex(ele => ele.territory_id === territory.id)

                    if (result_area === -1) {
                        //Extracts only ids from struct.
                        this.filterDataTerritories.push(territory.id)
                    }
                }

                //Extracts only ids from struct.
                for (const area of this.selectedAreasFilter) {
                    this.filterDataAreas.push(area.id)
                }
            },
            async loadData() {
                const dataAreas = await this.$http.get('/api/management/v1/area')
                this.dataAreas = dataAreas.data

                const dataTerritory = await this.$http.get('/api/management/v1/territory')
                this.dataTerritories = dataTerritory.data

                const dataDistricts = await this.$http.get('/api/management/v1/district')
                this.dataDistricts = dataDistricts.data

            },
            updateSelect(label, value, key) {

                if (value) {
                    this.selectOptions.push({ label, key})
                } else {
                    const index = this.selectOptions.findIndex(ele => ele.label === label)
                    Vue.delete(this.selectOptions, index)
                }
            },
            async getExportToExcel() {
                this.getNewData()
                this.columns = []
                let topTable = ''
                let tableLocation = []
                const tableUser = []
                const tableProduct = []
                const selectedFields = []
               
                //Check for which table we need to look for data
                if (!this.selectedSales.time && !this.selectedSales.qty && !this.selectedSales.price && !this.selectedProduct.category && !this.selectedProduct.name && !this.selectedProduct.unit && !this.selectedUser.username && !this.selectedUser.first_name && !this.selectedUser.last_name) {
                    
                    //This is executed if only fields outlet, area, territory, districts are selected
                    //We use only tabels that we actully need so it doesnt have to lookup data we wont use
                    if (this.selectedOutlet.name) {
                        tableLocation.push('outlets')
                    }

                    if (this.selectedArea.name || (this.selectedOutlet.name && (this.selectedTerritory.name || this.selectedDistrict.name))) {
                        tableLocation.push('areas')
                    }

                    if (this.selectedTerritory.name || ((this.selectedOutlet.name ||  this.selectedArea.name) && (this.selectedDistrict.name))) {
                        tableLocation.push('territories')
                    }

                    if (this.selectedDistrict.name) {
                        tableLocation.push('districts')
                    }

                } else if (this.selectedDistrict.name) { //All those if just check which location tables we need to include
                    tableLocation.push('outlets')
                    tableLocation.push('areas')
                    tableLocation.push('territories')
                    tableLocation.push('districts')
                } else if (this.selectedTerritory.name) {
                    tableLocation.push('outlets')
                    tableLocation.push('areas')
                    tableLocation.push('territories')
                } else if (this.selectedArea.name) {
                    tableLocation.push('outlets')
                    tableLocation.push('areas')
                } else if (this.selectedOutlet.name) {
                    tableLocation.push('outlets')
                }

                if (this.selectedProduct.category) {
                    tableProduct.push('products')
                    tableProduct.push('product_category')
                } else if (this.selectedProduct.name || this.selectedProduct.unit) {
                    tableProduct.push('products')
                }

                //Checks if any of users fields are selected, if they are we add users table to query
                if (this.selectedUser.username || this.selectedUser.first_name || this.selectedUser.last_name) {
                    tableUser.push('users')
                }

                //check if we want data that is conected through sales tables we want data for every user how many sales he have or which user is selling where
                if ((tableProduct.length > 0 && tableLocation.length > 0) || (tableProduct.length > 0 && tableUser.length > 0) || (tableLocation.length > 0 && tableUser.length > 0)) {
                    topTable = 'sales'
                } else if (tableProduct.length > 0) { //If we selected product name or category we set toptable based on that if its name it will set it to products otherwise it will set it to product_category
                    topTable = tableProduct[0]
                } else if (tableLocation.length > 0) { //Same as for products and category it will set top table to outlets, areas, territories or districts based on requested paramaters
                    topTable = tableLocation[0]
                } else if (tableUser.length > 0) { //It will set topTable to users
                    topTable = 'users'
                }

                //If any of those paramaters is selected we set topTable to sales
                if (this.selectedSales.time || this.selectedSales.qty || this.selectedSales.price) {
                    topTable = 'sales'
                }

                //If topTable is empty we cancel the function
                if (topTable === '') return

                //We just check if user wants data about user for districts, areas or territories. We have to add those tables. We add them all so we dont have to check for each of them.
                if ((this.selectedUser.username || this.selectedUser.first_name || this.selectedUser.last_name) && topTable === 'sales' && (this.selectedDistrict.name || this.selectedArea.name || this.selectedTerritory.name)) {
                    tableLocation = []

                    tableLocation.push('outlets')
                    tableLocation.push('areas')
                    tableLocation.push('territories')
                    tableLocation.push('districts')
                }


                //Check if filter needs anyother tables that were not selected
                const locationArea = tableLocation.findIndex(ele => ele === 'areas')
                const locationTerritory = tableLocation.findIndex(ele => ele === 'territories')
                const locationDistrict = tableLocation.findIndex(ele => ele === 'districts')

                //Check if user wants to filter by Area. If they did we have to include those tables so we can get data about areas and filter it
                if (this.filterDataAreas.length > 0 && locationArea === -1 && topTable !== 'sales') {
                    
                    //We just empty tableLocation so we dont have to check which tables are alredy in and which are not, and we just set all of them again
                    tableLocation = []

                    //If we are getting data from topTable we need to include it in tableLocation again becuse we set tableLocation to empty
                    if (topTable === 'outlets') {
                        tableLocation.push('outlets')
                    }

                    tableLocation.push('areas')
                    tableLocation.push('territories')
                    tableLocation.push('districts')

                    //We check if table is set to outlets. We leave it becuase we cannot get data about outlets from table areas but we can get data about areas from outlets
                    if (topTable !== 'outlets') topTable = 'areas'
                }

                //Same as for areas above
                if (this.filterDataTerritories.length > 0 && this.filterDataAreas.length === 0 && locationTerritory === -1 && topTable !== 'sales') {
                    tableLocation = []

                    tableLocation.push('areas')
                    tableLocation.push('territories')
                    tableLocation.push('districts')

                    topTable = 'territories'
                }

                //Same as for areas above
                if (this.filterDataDistricts.length > 0 &&  this.filterDataAreas.length === 0 && this.filterDataTerritories.length === 0 && locationDistrict === -1 && topTable !== 'sales') {

                    tableLocation.push('areas')
                    tableLocation.push('territories')
                    tableLocation.push('districts')

                    topTable = 'districts'
                }
               
                //Construct payload
                const payload = {
                    'table':topTable, 
                    'parameters': selectedFields,
                    'location': tableLocation,
                    'product': tableProduct,
                    'group-by': this.selected,
                    'tableFilter': this.filterTable,
                    'dataFilterAreas': this.filterDataAreas,
                    'dataFilterTerritories': this.filterDataTerritories,
                    'dataFilterDistricts': this.filterDataDistricts,
                    'user': tableUser
                }
                                

                //Code below just sets colums which we requested so it can displayed once we get data from server

                if (this.selectedArea.name) {
                    selectedFields.push('areas.name')
                    this.columns.push(
                        {
                            label: 'Area Name',
                            field: 'area_name'
                        }
                    )
                }

                if (this.selectedDistrict.name) {
                    selectedFields.push('districts.name')
                    this.columns.push(
                        {
                            label: 'District Name',
                            field: 'district_name'
                        }
                    )
                }

                if (this.selectedTerritory.name) {
                    selectedFields.push('territories.name')
                    this.columns.push(
                        {
                            label: 'Territory Name',
                            field: 'territory_name'
                        }
                    )
                }

                if (this.selectedOutlet.name) {
                    selectedFields.push('outlets.name')

                    this.columns.push(
                        {
                            label: 'Outlet Name',
                            field: 'outlet_name'
                        }
                    )
                }

                if (this.selectedProduct.name) {
                    selectedFields.push('products.name')
                    this.columns.push(
                        {
                            label: 'Product Name',
                            field: 'product_name'
                        }
                    )
                }

                if (this.selectedProduct.unit) {
                    selectedFields.push('products.unit')
                    this.columns.push(
                        {
                            label: 'Unit',
                            field: 'unit'
                        }
                    )
                }

                if (this.selectedProduct.category) {
                    selectedFields.push('product_category.name')

                    this.columns.push(
                        {
                            label: 'Category',
                            field: 'category'
                        }
                    )
                }

                if (this.selectedSales.time) {
                    selectedFields.push('time')
                    this.columns.push(
                        {
                            label: 'Date',
                            field: 'date'
                        }
                    )
                }

                if (this.selectedSales.qty) {
                    selectedFields.push('items.quantity')

                    this.columns.push(
                        {
                            label: 'Quantity',
                            field: 'quantity'
                        }
                    )
                }

                if (this.selectedSales.price) {
                    selectedFields.push('items.price_with_vat')

                    this.columns.push(
                        {
                            label: 'Price (with vat)',
                            field: 'price_with_vat'
                        }
                    )
                }

                if (this.selectedUser.username) {
                    selectedFields.push('users.username')

                    this.columns.push(
                        {
                            label: 'Username',
                            field: 'username'
                        }
                    )
                }

                if (this.selectedUser.last_name) {
                    selectedFields.push('users.last_name')

                    this.columns.push(
                        {
                            label: 'Last Name',
                            field: 'last_name'
                        }
                    )
                }

                if (this.selectedUser.first_name) {
                    selectedFields.push('users.name')

                    this.columns.push(
                        {
                            label: 'First Name',
                            field: 'name'
                        }
                    )
                }
                
                this.rows = []

                const dateFromFormated = this.moment(this.dateFrom).startOf('day').format('YYYY-MM-DD')
                const dateToFormated = this.moment(this.dateTo).endOf('day').format('YYYY-MM-DD')
                // const data = await this.$http.get('/api/management/v1/export/outlets')

                this.$http({
                    url: `/api/management/v1/report/export_custom_report_to_excel?date_from=${dateFromFormated}&date_to=${dateToFormated}`, //your url
                    method: 'PUT',
                    responseType: 'blob', // important
                    data: payload
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', 'file.xlsx') //or any other extension
                    document.body.appendChild(link)
                    link.click()
                })
                
                // this.rows = data.data

            },
            async getCustomReportData() {
                this.getNewData()
                this.columns = []
                let topTable = ''
                let tableLocation = []
                const tableUser = []
                const tableProduct = []
                const selectedFields = []
               
                //Check for which table we need to look for data
                if (!this.selectedSales.time && !this.selectedSales.qty && !this.selectedSales.price && !this.selectedProduct.category && !this.selectedProduct.name && !this.selectedProduct.unit && !this.selectedUser.username && !this.selectedUser.first_name && !this.selectedUser.last_name) {
                    
                    //This is executed if only fields outlet, area, territory, districts are selected
                    //We use only tabels that we actully need so it doesnt have to lookup data we wont use
                    if (this.selectedOutlet.name) {
                        tableLocation.push('outlets')
                    }

                    if (this.selectedArea.name || (this.selectedOutlet.name && (this.selectedTerritory.name || this.selectedDistrict.name))) {
                        tableLocation.push('areas')
                    }

                    if (this.selectedTerritory.name || ((this.selectedOutlet.name ||  this.selectedArea.name) && (this.selectedDistrict.name))) {
                        tableLocation.push('territories')
                    }

                    if (this.selectedDistrict.name) {
                        tableLocation.push('districts')
                    }

                } else if (this.selectedDistrict.name) { //All those if just check which location tables we need to include
                    tableLocation.push('outlets')
                    tableLocation.push('areas')
                    tableLocation.push('territories')
                    tableLocation.push('districts')
                } else if (this.selectedTerritory.name) {
                    tableLocation.push('outlets')
                    tableLocation.push('areas')
                    tableLocation.push('territories')
                } else if (this.selectedArea.name) {
                    tableLocation.push('outlets')
                    tableLocation.push('areas')
                } else if (this.selectedOutlet.name) {
                    tableLocation.push('outlets')
                }

                if (this.selectedProduct.category) {
                    tableProduct.push('products')
                    tableProduct.push('product_category')
                } else if (this.selectedProduct.name || this.selectedProduct.unit) {
                    tableProduct.push('products')
                }

                //Checks if any of users fields are selected, if they are we add users table to query
                if (this.selectedUser.username || this.selectedUser.first_name || this.selectedUser.last_name) {
                    tableUser.push('users')
                }

                //check if we want data that is conected through sales tables we want data for every user how many sales he have or which user is selling where
                if ((tableProduct.length > 0 && tableLocation.length > 0) || (tableProduct.length > 0 && tableUser.length > 0) || (tableLocation.length > 0 && tableUser.length > 0)) {
                    topTable = 'sales'
                } else if (tableProduct.length > 0) { //If we selected product name or category we set toptable based on that if its name it will set it to products otherwise it will set it to product_category
                    topTable = tableProduct[0]
                } else if (tableLocation.length > 0) { //Same as for products and category it will set top table to outlets, areas, territories or districts based on requested paramaters
                    topTable = tableLocation[0]
                } else if (tableUser.length > 0) { //It will set topTable to users
                    topTable = 'users'
                }

                //If any of those paramaters is selected we set topTable to sales
                if (this.selectedSales.time || this.selectedSales.qty || this.selectedSales.price) {
                    topTable = 'sales'
                }

                //If topTable is empty we cancel the function
                if (topTable === '') return

                //We just check if user wants data about user for districts, areas or territories. We have to add those tables. We add them all so we dont have to check for each of them.
                if ((this.selectedUser.username || this.selectedUser.first_name || this.selectedUser.last_name) && topTable === 'sales' && (this.selectedDistrict.name || this.selectedArea.name || this.selectedTerritory.name)) {
                    tableLocation = []

                    tableLocation.push('outlets')
                    tableLocation.push('areas')
                    tableLocation.push('territories')
                    tableLocation.push('districts')
                }


                //Check if filter needs anyother tables that were not selected
                const locationArea = tableLocation.findIndex(ele => ele === 'areas')
                const locationTerritory = tableLocation.findIndex(ele => ele === 'territories')
                const locationDistrict = tableLocation.findIndex(ele => ele === 'districts')

                //Check if user wants to filter by Area. If they did we have to include those tables so we can get data about areas and filter it
                if (this.filterDataAreas.length > 0 && locationArea === -1 && topTable !== 'sales') {
                    
                    //We just empty tableLocation so we dont have to check which tables are alredy in and which are not, and we just set all of them again
                    tableLocation = []

                    //If we are getting data from topTable we need to include it in tableLocation again becuse we set tableLocation to empty
                    if (topTable === 'outlets') {
                        tableLocation.push('outlets')
                    }

                    tableLocation.push('areas')
                    tableLocation.push('territories')
                    tableLocation.push('districts')

                    //We check if table is set to outlets. We leave it becuase we cannot get data about outlets from table areas but we can get data about areas from outlets
                    if (topTable !== 'outlets') topTable = 'areas'
                }

                //Same as for areas above
                if (this.filterDataTerritories.length > 0 && this.filterDataAreas.length === 0 && locationTerritory === -1 && topTable !== 'sales') {
                    tableLocation = []

                    tableLocation.push('areas')
                    tableLocation.push('territories')
                    tableLocation.push('districts')

                    topTable = 'territories'
                }

                //Same as for areas above
                if (this.filterDataDistricts.length > 0 &&  this.filterDataAreas.length === 0 && this.filterDataTerritories.length === 0 && locationDistrict === -1 && topTable !== 'sales') {

                    tableLocation.push('areas')
                    tableLocation.push('territories')
                    tableLocation.push('districts')

                    topTable = 'districts'
                }
               
                //Construct payload
                const payload = {
                    'table':topTable, 
                    'parameters': selectedFields,
                    'location': tableLocation,
                    'product': tableProduct,
                    'group-by': this.selected,
                    'tableFilter': this.filterTable,
                    'dataFilterAreas': this.filterDataAreas,
                    'dataFilterTerritories': this.filterDataTerritories,
                    'dataFilterDistricts': this.filterDataDistricts,
                    'user': tableUser
                }
                                

                //Code below just sets colums which we requested so it can displayed once we get data from server

                if (this.selectedArea.name) {
                    selectedFields.push('areas.name')
                    this.columns.push(
                        {
                            label: 'Area Name',
                            field: 'area_name'
                        }
                    )
                }

                if (this.selectedDistrict.name) {
                    selectedFields.push('districts.name')
                    this.columns.push(
                        {
                            label: 'District Name',
                            field: 'district_name'
                        }
                    )
                }

                if (this.selectedTerritory.name) {
                    selectedFields.push('territories.name')
                    this.columns.push(
                        {
                            label: 'Territory Name',
                            field: 'territory_name'
                        }
                    )
                }

                if (this.selectedOutlet.name) {
                    selectedFields.push('outlets.name')

                    this.columns.push(
                        {
                            label: 'Outlet Name',
                            field: 'outlet_name'
                        }
                    )
                }

                if (this.selectedProduct.name) {
                    selectedFields.push('products.name')
                    this.columns.push(
                        {
                            label: 'Product Name',
                            field: 'product_name'
                        }
                    )
                }

                if (this.selectedProduct.unit) {
                    selectedFields.push('products.unit')
                    this.columns.push(
                        {
                            label: 'Unit',
                            field: 'unit'
                        }
                    )
                }

                if (this.selectedProduct.category) {
                    selectedFields.push('product_category.name')

                    this.columns.push(
                        {
                            label: 'Category',
                            field: 'category'
                        }
                    )
                }

                if (this.selectedSales.time) {
                    selectedFields.push('time')
                    this.columns.push(
                        {
                            label: 'Date',
                            field: 'date'
                        }
                    )
                }

                if (this.selectedSales.qty) {
                    selectedFields.push('items.quantity')

                    this.columns.push(
                        {
                            label: 'Quantity',
                            field: 'quantity'
                        }
                    )
                }

                if (this.selectedSales.price) {
                    selectedFields.push('items.price_with_vat')

                    this.columns.push(
                        {
                            label: 'Price (with vat)',
                            field: 'price_with_vat'
                        }
                    )
                }

                if (this.selectedUser.username) {
                    selectedFields.push('users.username')

                    this.columns.push(
                        {
                            label: 'Username',
                            field: 'username'
                        }
                    )
                }

                if (this.selectedUser.last_name) {
                    selectedFields.push('users.last_name')

                    this.columns.push(
                        {
                            label: 'Last Name',
                            field: 'last_name'
                        }
                    )
                }

                if (this.selectedUser.first_name) {
                    selectedFields.push('users.name')

                    this.columns.push(
                        {
                            label: 'First Name',
                            field: 'name'
                        }
                    )
                }
                
                this.rows = []

                const dateFromFormated = this.moment(this.dateFrom).startOf('day').format('YYYY-MM-DD')
                const dateToFormated = this.moment(this.dateTo).endOf('day').format('YYYY-MM-DD')
                
                const data = await this.$http.put(`/api/management/v1/report/custom_report?date_from=${dateFromFormated}&date_to=${dateToFormated}`, payload)
                this.rows = data.data

            }

        },
        mounted() {
            this.loadData()
        }
    
    }

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>